import React from 'react'
import PropTypes from 'prop-types';

const RadioButton = ({ isSelected, className, isDisabled, radioGroupName, label, isLabel, labelClass, handlerRadioChange, value, data = '', dataAutomation, isShowOnlyDisableStyles, handlerCheckboxChange, title, id, payload, checked, selectedStock, medicineType,
    radioButtonClass="w-6 h-6",
}) => {
    const Type = isLabel ? 'label' : 'div';
    const radioValue = String(value).trim();

    return (
        <Type className={`${className} ${isDisabled ? 'cursor-not-allowed' : ''} radio`} data-automation={dataAutomation + '-label'}>
            {['Categories', 'Experience', 'Gender', 'Fee', 'Speciality', 'Language', 'Consultation Type']?.includes(title) ? (
                <input 
                    hidden 
                    checked={medicineType ? (isSelected && medicineType == selectedStock) : isSelected} 
                    type="radio" 
                    id={data} 
                    name={radioGroupName}
                    className="opacity-0 absolute w-6 h-6 cursor-pointer radioInput"
                    {...({
                        onChange: () => {
                            handlerCheckboxChange({ checked: !isSelected, id, label, payload })
                        }
                    })}
                    value={value} 
                    disabled={isShowOnlyDisableStyles ? false : isDisabled}
                />
            ) : (
                <input
                    hidden
                    checked={isSelected}
                    type="radio"
                    id={data}
                    name={radioGroupName}
                    className="opacity-0 absolute w-6 h-6 cursor-pointer radioInput"
                    onChange={handlerRadioChange}
                    value={value}
                    disabled={isShowOnlyDisableStyles ? false : isDisabled}
                />
            )}

            <div className={`${radioButtonClass} ${isDisabled ? 'radio--disable' : ''} radio__circle`} data-automation={dataAutomation}>
                <div className='rounded-full bg-primary1-500'></div>
            </div>
            {label && <span className={`${labelClass} radio__label`}>{label}</span>}
        </Type>
    )
}

RadioButton.defaultProps = {
    className: '',
    isDisabled: false,
    isLabel: true,
    labelClass: '',
    isShowOnlyDisableStyles: false,
}

RadioButton.propTypes = {
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    isLabel: PropTypes.bool,
    labelClass: PropTypes.string,
    isDisabled: PropTypes.bool,
    isShowOnlyDisableStyles: PropTypes.bool,
}

export default RadioButton
